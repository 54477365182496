const wordPressSiteUrl = process.env.SITE_URL || 'https://backendemi.citizenshareholders.org';

/**
 * Get Wordpress CSS Links data
 *
 * @param {int} postId post ID.
 *
 * @return {Array} Wordpress CSS Links data.
 */
export const getWordpressCssLinksData = (postId) => {
  return [
    {
      id: 'astra-css',
      link: `${wordPressSiteUrl}/wp-includes/css/dist/block-library/style.min.css?ver=5.6`,
    },
  ];
};
