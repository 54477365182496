import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { getWordpressCssLinksData } from '@shared/utils/wordpress-styles';
import Layout from '@org/components/layout';
import Seo from '@org/components/seo';
import { graphql } from 'gatsby';

const Post = ({
  data: {
    wpPost: { id, title, content, elementorData, featuredImage, uri, databaseId },
  },
}) => {
  const wordpressCssLinksData = getWordpressCssLinksData(databaseId);

  return (
    <Layout>
      <Seo title={title} uri={uri} />
      <Helmet>
        {wordpressCssLinksData?.length &&
          wordpressCssLinksData.map((linkData) => (
            <link
              key={linkData?.id}
              rel="stylesheet"
              id={linkData?.id}
              href={linkData?.link}
              media="all"
            />
          ))}
      </Helmet>
      <div className="wordpress-blog-content container mx-auto p-4">
        <div>
          <article
            data-id={id}
            id={`post-${databaseId}`}
            className={`post-${databaseId} post-content`}
          >
            <div className="entry-content" dangerouslySetInnerHTML={{ __html: content }} />
            {/* .entry-content */}
          </article>
        </div>
      </div>
    </Layout>
  );
};

Post.propTypes = {
  data: PropTypes.object,
};

export const query = graphql`
  query($slug: String!) {
    wpPost(slug: { eq: $slug }) {
      id
      title
      excerpt
      date(formatString: "DD/MM/YYYY")
      slug
      content
      uri
      databaseId
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 600, layout: CONSTRAINED)
              original {
                width
                height
              }
            }
          }
        }
      }
    }
  }
`;

export default Post;
